import AIAgentService from "services/AIAgent.service"
import { useMutation, useQuery } from "@tanstack/react-query"

export const useGetQuestions = () => {
	return useQuery({
		queryKey: ["questions"],
		queryFn: () => AIAgentService.getQuestions(),
		select: ({ data }) => data,
	})
}

export const useAskQuestion = () => {
	return useMutation({
		mutationKey: ["askQuestion"],
		mutationFn: ({ id, text }: { id: number; text: string }) => AIAgentService.askQuestion(id, text),
	})
}
