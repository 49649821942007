import { ReactNode, CSSProperties } from "react"

type TypeTitleProps = {
	children: ReactNode
	style?: CSSProperties
	className?: string
}

export const H1 = ({ children, style, className = "" }: TypeTitleProps) => {
	return (
		<h1 className={`${className}`} style={style}>
			{children}
		</h1>
	)
}

export const H2 = ({ children, style, className = "" }: TypeTitleProps) => {
	return (
		<h2 className={`${className}`} style={style}>
			{children}
		</h2>
	)
}

export const H3 = ({ children, style, className = "" }: TypeTitleProps) => {
	return (
		<h3 className={`${className}`} style={style}>
			{children}
		</h3>
	)
}

export const H4 = ({ children, style, className = "" }: TypeTitleProps) => {
	return (
		<h4 className={`${className}`} style={style}>
			{children}
		</h4>
	)
}
