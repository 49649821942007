import { Navbar } from "components/ui/Navbar/Navbar"
import { PageAllFiles } from "components/pages/PageAllFiles/PageAllFiles"
import { PageAuth } from "components/pages/PageAuth/PageAuth"
import { PageConfiguration } from "components/pages/PageConfiguration/PageConfiguration"
import { PageTeam } from "components/pages/PageTeam/PageTeam"
import { PageAbout } from "components/pages/PageAbout/PageAbout"
import { PageRegistration } from "components/pages/PageRegistration/PageRegistration"
import { Route, Navigate } from "react-router-dom"
import { PageRegistrationActivation } from "components/pages/PageRegistrationActivation/PageRegistrationActivation"
import { SentryRoutes } from "./sentry"

export const useRoutes = (isAuthenticated: boolean) => {
	if (isAuthenticated) {
		return (
			<main className="page">
				<Navbar />
				<SentryRoutes>
					<Route path="/all-files" element={<PageAllFiles />} />
					<Route path="/configuration" element={<PageConfiguration />} />
					<Route path="/team" element={<PageTeam />} />
					<Route path="/about" element={<PageAbout />} />
					<Route path="*" element={<Navigate to="/all-files" replace />} />
				</SentryRoutes>
			</main>
		)
	}

	return (
		<SentryRoutes>
			<Route path="/" element={<PageAuth />} />
			<Route path="/signup" element={<PageRegistration />} />
			<Route path="/registration-activation" element={<PageRegistrationActivation />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</SentryRoutes>
	)
}
