import { create } from "zustand"
import {
	TFileModal,
	TDeleteConfirmModal,
	TModelNumbersModal,
	TAddSimpleCategoryModalStore,
	TAddDualCategoryModalStore,
} from "types/modal.type"

export const useFileModalStore = create<TFileModal>((set) => ({
	isFileModalOpen: false,
	triggerFileModal: () => set((state) => ({ isFileModalOpen: !state.isFileModalOpen })),
	setFileModalClose: () => set(() => ({ isFileModalOpen: false })),
}))

export const useDeleteConfirmModalStore = create<TDeleteConfirmModal>((set) => ({
	isDeleteConfirmModalOpen: false,
	trigerDeleteConfirmModal: () => set((state) => ({ isDeleteConfirmModalOpen: !state.isDeleteConfirmModalOpen })),
	setDeleteConfirmDodalClose: () => set(() => ({ isDeleteConfirmModalOpen: false })),

	deleteFile: { id: 0, fileName: "" },
	setDeleteFile: (file) => set(() => ({ deleteFile: file })),
}))

export const useModelNumbersModalStore = create<TModelNumbersModal>((set) => ({
	modelNumbers: [""],
	setModelNumbers: (newModelNumbers) => set(() => ({ modelNumbers: newModelNumbers })),
	updateModelNumbers: (newModelNumbers) => set((state) => ({ modelNumbers: [...state.modelNumbers, newModelNumbers] })),
	clearModelNumbers: () => set(() => ({ modelNumbers: [""] })),

	isModelNumbersModalOpen: false,
	trigerModelNumbersModal: () => set((state) => ({ isModelNumbersModalOpen: !state.isModelNumbersModalOpen })),
	setModelNumbersModalClose: () => set(() => ({ isModelNumbersModalOpen: false })),

	objectName: "",
	setObjectName: (newObjectName) => set(() => ({ objectName: newObjectName })),

	categoryId: null,
	setCategoryId: (id) => set(() => ({ categoryId: id })),

	initialModelNumbers: [""],
	clearInitilaModelNumbers: () => set(() => ({ initialModelNumbers: [""] })),
	setInitialModelNumbers: (modelNumbers) => set(() => ({ initialModelNumbers: modelNumbers })),
}))

// Add category stores
export const useAddSimpleCategoryStore = create<TAddSimpleCategoryModalStore>((set) => ({
	category: { name: "", label: "", topLevelCategory: "", superCategory: "", modelNumbers: [] },

	setFormValues: ({ name, label, topLevelCategory, superCategory, modelNumbers }) =>
		set((state) => ({
			category: { ...state.category, name, label, topLevelCategory, superCategory, modelNumbers },
		})),

	resetForm: () =>
		set(() => ({
			category: { name: "", label: "", topLevelCategory: "", superCategory: "", modelNumbers: [] },
		})),
}))

export const useAddDualPartCategoryStore = create<TAddDualCategoryModalStore>((set) => ({
	category: {
		name: "",
		label: "",
		topLevelCategory: "",
		superCategory: "",
		rightModelNumbers: [],
		leftModelNumbers: [],
		wholeModelNumbers: [],
	},

	setFormValues: ({
		name,
		label,
		topLevelCategory,
		superCategory,
		rightModelNumbers,
		leftModelNumbers,
		wholeModelNumbers,
	}) =>
		set((state) => ({
			category: {
				...state.category,
				name,
				label,
				topLevelCategory,
				superCategory,
				rightModelNumbers,
				leftModelNumbers,
				wholeModelNumbers,
			},
		})),

	resetForm: () =>
		set(() => ({
			category: {
				name: "",
				label: "",
				topLevelCategory: "",
				superCategory: "",
				rightModelNumbers: [],
				leftModelNumbers: [],
				wholeModelNumbers: [],
			},
		})),
}))
