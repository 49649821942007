import { UploadbarStatus } from "../UploadbarStatus/UploadbarStatus"
import { UploadbarStatusBadge } from "../UploadbarStatusBadge/UploadbarStatusBadge"
import { formatEstimateTime } from "helpers/utils.helper"
import { CancelUploadIcon } from "components/ui/icons/uploadbar/CancelUploadIcon"
import { useFilesToAbortStore } from "store/uploadFiles.store"
import type { TFileUploadingDataStatus } from "types/uploadFiles.type"
import style from "./UploadbarFile.module.scss"

const { uploadbarStatus, status, failed, block, fileName, info, timeLeft, uploading, notUploading } = style

type Props = {
	file: TFileUploadingDataStatus
}

export const UploadbarFile = ({ file }: Props) => {
	const { filesToAbort } = useFilesToAbortStore()

	const estimatedInMinutes = file.estimated
	const fileStatus = file.status

	const cancelUploadHandle = () => {
		const uploadFileId = file.id

		if (uploadFileId) {
			const controller = filesToAbort[uploadFileId]
			if (controller) controller.abort() // Proceed with abort operation
		}
	}

	return (
		<div className={uploadbarStatus}>
			<div onClick={cancelUploadHandle}>
				<CancelUploadIcon className={`${fileStatus === "uploading" ? uploading : notUploading}`} />
			</div>
			<div className={`${status} ${fileStatus === "failed" ? failed : ""}`}>
				<UploadbarStatus file={file} />
			</div>
			<div className={block}>
				<div className={fileName}>{file.name}</div>
				<div className={info}>
					{fileStatus && <UploadbarStatusBadge status={fileStatus} />}
					<span className={timeLeft}>
						{fileStatus === "canceled" ? "" : formatEstimateTime(estimatedInMinutes || 0)}
					</span>
				</div>
			</div>
		</div>
	)
}
