import { Skeleton } from "components/ui/skeleton/Skeleton"
import { H1 } from "components/ui/typography/titles/Titles"
import { AddModelObject } from "./layout/AddModelObject/AddModelObject"
import { Categories } from "./layout/Categories/Categories"
import { useObjectsCategories } from "hooks/objectsCategories.hook"
import style from "./ObjectsCategories.module.scss"

const { objectsCategories, topBlock, skeletonContainer } = style

export const ObjectsCategories = () => {
	const {
		data: objectCategoriesData,
		isPending: objectCategoriesPending,
		error: objectCategoriesError,
		refetch: objectCategoriesRefetch,
	} = useObjectsCategories()

	if (objectCategoriesPending) {
		return (
			<div className={skeletonContainer} data-testid="skeleton">
				<Skeleton rows={12} />
			</div>
		)
	}

	return (
		<div className={objectsCategories}>
			<div className={topBlock}>
				<H1>Categories of objects</H1>
				<AddModelObject objectCategoriesRefetch={objectCategoriesRefetch} />
			</div>
			<div>
				<Categories
					objectCategoriesData={objectCategoriesData}
					objectCategoriesRefetch={objectCategoriesRefetch}
					objectCategoriesError={objectCategoriesError}
				/>
			</div>
		</div>
	)
}
