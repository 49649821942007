import { Select as AntSelect } from "antd"
import type { DefaultOptionType } from "antd/es/select"
import type { Variant } from "antd/es/form/hooks/useVariants"
import type { SelectProps } from "antd"

type Props = {
	variant: Variant
	options: DefaultOptionType[]
	placement?: SelectProps["placement"]
	value?: string | number | null
	open?: boolean
	placeholder?: string
	className?: string
	allowClear?: boolean
	onChange?: (e: string | number) => void
	onDropdownVisibleChange?: (arg: boolean) => void
}

export const Select = ({
	variant,
	options,
	placement = "bottomRight",
	value,
	placeholder,
	className,
	allowClear = false,
	onChange,
	onDropdownVisibleChange,
}: Props) => {
	return (
		<AntSelect
			placeholder={placeholder}
			allowClear={allowClear}
			variant={variant}
			options={options}
			placement={placement}
			value={value}
			onChange={onChange}
			onDropdownVisibleChange={onDropdownVisibleChange}
			className={className}
		/>
	)
}
