export const AIAgentWhiteIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.7159 12.5246C18.7159 12.0796 18.4659 11.6886 18.0679 11.5056L13.8979 9.52755L11.9809 5.41855C11.7949 5.01755 11.4049 4.76855 10.9629 4.76855C10.5209 4.76855 10.1309 5.01755 9.94392 5.41855L7.96692 9.58656L3.86092 11.5036C3.45892 11.6886 3.20892 12.0796 3.20892 12.5246C3.20892 12.9686 3.45892 13.3596 3.85792 13.5426L8.02592 15.5196L9.94392 19.6306C10.1309 20.0306 10.5209 20.2786 10.9629 20.2786C11.4039 20.2786 11.7949 20.0306 11.9809 19.6296L13.9589 15.4626L18.0639 13.5446C18.4659 13.3596 18.7159 12.9686 18.7159 12.5246Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.0931 4.68295C20.0931 4.56045 20.0243 4.45282 19.9147 4.40244L18.7668 3.85793L18.239 2.72678C18.1878 2.61639 18.0805 2.54785 17.9588 2.54785C17.8371 2.54785 17.7298 2.61639 17.6783 2.72678L17.134 3.87417L16.0037 4.40189C15.8931 4.45282 15.8242 4.56045 15.8242 4.68295C15.8242 4.80518 15.8931 4.91282 16.0029 4.96319L17.1503 5.50743L17.6783 6.63913C17.7298 6.74924 17.8371 6.81751 17.9588 6.81751C18.0802 6.81751 18.1878 6.74924 18.239 6.63885L18.7836 5.49174L19.9136 4.96374C20.0243 4.91282 20.0931 4.80518 20.0931 4.68295Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.4598 18.8788C22.4598 18.7313 22.377 18.6017 22.2451 18.5411L20.8633 17.8856L20.228 16.524C20.1664 16.3911 20.0371 16.3086 19.8907 16.3086C19.7442 16.3086 19.615 16.3911 19.553 16.524L18.8979 17.9052L17.5372 18.5404C17.404 18.6017 17.3212 18.7313 17.3212 18.8788C17.3212 19.0259 17.404 19.1555 17.5362 19.2161L18.9174 19.8712L19.553 21.2335C19.615 21.3661 19.7442 21.4483 19.8907 21.4483C20.0368 21.4483 20.1664 21.3661 20.228 21.2332L20.8835 19.8523L22.2438 19.2168C22.377 19.1555 22.4598 19.0259 22.4598 18.8788Z"
				fill="white"
			/>
		</svg>
	)
}
