import { useEffect, useRef, useState } from "react"
import { Select } from "components/ui/select/Select"
import { useProcessings } from "hooks/drawings.hook"
import { useSearchParams } from "react-router-dom"
import { useGeneralFeedbackStore } from "store/drawer.store"
import type { DefaultOptionType } from "antd/es/select"
import style from "./FileInfoProcessingRuns.module.scss"

const { fileInfoProcessingRuns, text } = style

export const FileInfoProcessingRuns = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>()
	const { setGeneralFeedback } = useGeneralFeedbackStore()
	const { data: processingRuns } = useProcessings(drawingId)

	const processingRunRef = useRef<HTMLDivElement | null>(null)

	// Set the processing runs as select options
	useEffect(() => {
		if (processingRuns) {
			const selectOptions: DefaultOptionType[] = Object.entries(processingRuns).map(([label, value]) => ({
				label,
				value: value as number,
			}))
			setSelectOptions(selectOptions)
		}
	}, [processingRuns])

	// Set default vlaue of latestProcessingId as URL param
	useEffect(() => {
		if (selectOptions) {
			const latestProcessing = selectOptions[0]?.value
			const latestProcesingAsString = latestProcessing as string

			setSearchParams((prev) => {
				const newParams = new URLSearchParams(prev)
				newParams.set("processingID", latestProcesingAsString)
				return newParams
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectOptions])

	const selectChangeHandle = (e: string | number) => {
		const processingId = e.toString()
		setGeneralFeedback("") // Clear general feedback active state

		setSearchParams((prev) => {
			const newParams = new URLSearchParams(prev)
			newParams.set("processingID", processingId)
			return newParams
		})
	}

	// Change border color if select is open
	const onDropdownVisibleChangeHandle = (open: boolean) => {
		if (processingRunRef.current) {
			if (open) {
				processingRunRef.current.style.border = "1px solid #888DA6"
			} else {
				processingRunRef.current.style.border = "1px solid #DEE0EB"
			}
		}
	}

	return selectOptions ? (
		<div className={fileInfoProcessingRuns} ref={processingRunRef}>
			<span className={text}>Processing run:</span>
			<Select
				variant="borderless"
				value={processingId}
				options={selectOptions}
				onChange={selectChangeHandle}
				onDropdownVisibleChange={onDropdownVisibleChangeHandle}
			/>
		</div>
	) : null
}
