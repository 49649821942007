import { axiosInstance } from "api/axios.instance"

class AIAgentService {
	static BASE_URL = "questions"

	async getQuestions() {
		return axiosInstance.get(`${AIAgentService.BASE_URL}/`)
	}

	async askQuestion(id: number, text: string) {
		return axiosInstance.post(`${AIAgentService.BASE_URL}/${id}/ask/`, { text: text })
	}
}
export default new AIAgentService()
