import { useState } from "react"
import { InputBorderless } from "../inputs/InputBorderless"
import { Tag } from "../tag/Tag"
import { CloseIcon } from "../icons/close/CloseIcon"
import { TOnChangeInput } from "types/default.type"
import style from "./ModelNumbersTextarea.module.scss"

const { modelNumbersTexarea, tags } = style

type Props = {
	modelNumbers: string[]
	setModelNumbers: (modelNumbers: string[]) => void
}

export const ModelNumbersTextarea = ({ modelNumbers, setModelNumbers }: Props) => {
	const [inputValue, setInputValue] = useState<string | undefined>()

	const onCloseHandle = (removedTag: string) => {
		const updatedModelNumbers = modelNumbers?.filter((tag) => tag !== removedTag)
		setModelNumbers(updatedModelNumbers)
	}

	const inputChangeHandle = (e: TOnChangeInput) => {
		const value = e.target.value
		setInputValue(value)
	}

	const onConfirmHandle = () => {
		if (inputValue && !modelNumbers?.includes(inputValue)) {
			setModelNumbers([...modelNumbers, inputValue])
		}
		setInputValue("")
	}

	return (
		<div className={modelNumbersTexarea}>
			<div className={tags}>
				{modelNumbers?.map((modelNumber) => (
					<Tag key={modelNumber} closeIcon={<CloseIcon />} onClose={() => onCloseHandle(modelNumber)}>
						{modelNumber}
					</Tag>
				))}
			</div>
			<InputBorderless
				inputChangeHandle={inputChangeHandle}
				onConfirmHandle={onConfirmHandle}
				value={inputValue}
				placeholder="Press Enter to add a number"
			/>
		</div>
	)
}
