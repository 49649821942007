import { useEffect, useState } from "react"
import type { DefaultOptionType } from "antd/es/select"
import type { TTopLevelCategory } from "types/drawer.type"

export const useCategories = (
	objectCategoriesData: TTopLevelCategory[],
	selectedTopLevelCategory: string | number | undefined,
) => {
	const [topLevelCategories, setTopLevelCategories] = useState<DefaultOptionType[]>([])
	const [superCategories, setSuperCategories] = useState<DefaultOptionType[]>([])

	useEffect(() => {
		if (objectCategoriesData) {
			const topLevelCategories = Object.keys(objectCategoriesData).map((category) => ({
				label: category,
				value: category,
			}))
			setTopLevelCategories(topLevelCategories)

			const superCategories = Object.entries(objectCategoriesData)
				.filter(([topLevelCategory]) => topLevelCategory === selectedTopLevelCategory)
				.flatMap(([, superCategories]) =>
					Object.keys(superCategories).map((superCategory) => ({
						label: superCategory,
						value: superCategory,
					})),
				)
			setSuperCategories(superCategories)
		}
	}, [objectCategoriesData, selectedTopLevelCategory])

	return { topLevelCategories, superCategories }
}
