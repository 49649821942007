import { ConfigProvider } from "antd"
import { ReactNode } from "react"

type Props = {
	children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					marginLG: 10,
					borderRadiusLG: 12,
					fontSize: 18,
					colorTextPlaceholder: "rgba(136, 141, 166, 1)",
				},
				components: {
					Input: {
						paddingBlockLG: 10,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}

export const AddModelObjectProvider = ({ children }: Props) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					marginLG: 10,
					borderRadiusLG: 12,
					fontSize: 18,
					colorTextPlaceholder: "rgba(136, 141, 166, 1)",
				},
				components: {
					Input: {
						paddingBlockLG: 10,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	)
}
