import { H2 } from "components/ui/typography/titles/Titles"
import { Input, Form } from "antd"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { useEffect, useState } from "react"
import { ValidationBlock } from "../ValidationBlock/ValidationBlock"
import { AuthProvider } from "components/providers/Forms.provider"
import { useRegistrationStore } from "store/auth.store"
import { useRegistration } from "hooks/auth.hook"
import { useMessage } from "hooks/message.hook"
import { useRegistrationStepsStore } from "store/auth.store"
import { AxiosError } from "axios"
import style from "./UserPassword.module.scss"

const { title } = style

type Props = { token: string | undefined | null }

export const UserPassword = ({ token }: Props) => {
	const [submittable, setSubmittable] = useState(false)
	const [validatePassword, setValidatePassword] = useState({
		lowercase: false,
		numbers: false,
		uppercase: false,
		minimumCharacters: false,
		specialCharacters: false,
	})

	const { mutate: registrationMutate } = useRegistration()
	const { setRegistration, data } = useRegistrationStore()
	const { setStep } = useRegistrationStepsStore()

	const { error } = useMessage()

	const [form] = Form.useForm()
	const values = Form.useWatch([], form)

	useEffect(() => {
		if (!values?.password) {
			// If password is not defined or empty, set all validations to false.
			setValidatePassword({
				lowercase: false,
				numbers: false,
				uppercase: false,
				minimumCharacters: false,
				specialCharacters: false,
			})
			return
		}

		const password = values.password
		const regexLowercase = /[a-z]/
		const regexNumber = /\d/
		const regexUppercase = /[A-Z]/
		const regexMinimumCharacters = /^.{8,32}$/
		const regexSpecialCharacters = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]/

		const newPasswordValidationState = {
			lowercase: regexLowercase.test(password),
			numbers: regexNumber.test(password),
			uppercase: regexUppercase.test(password),
			minimumCharacters: regexMinimumCharacters.test(password),
			specialCharacters: regexSpecialCharacters.test(password),
		}

		setValidatePassword(newPasswordValidationState)
	}, [values])

	useEffect(() => {
		const allKeysAreValid = Object.values(validatePassword).every((val) => val)
		const passwordMatch = values?.password === values?.confirmPassword

		setRegistration({
			password: values?.password,
			confirmPassword: values?.confirmPassword,
			token: token,
		})

		setSubmittable(passwordMatch && allKeysAreValid)
	}, [validatePassword, values?.password, values?.confirmPassword, setRegistration, token])

	useEffect(() => {
		form.validateFields({ validateOnly: true }).then(
			() => {
				const password = values?.password
				const confirmPassword = values?.confirmPassword
				const passwordMatch = password === confirmPassword

				setSubmittable(passwordMatch)
			},
			() => {
				setSubmittable(false)
			},
		)
	}, [values, form])

	const onSubmitHandle = () => {
		setSubmittable(false)
		registrationMutate(data, {
			onSuccess: () => {
				setStep(3)
			},
			onError: (e) => {
				setSubmittable(true)
				if (e instanceof AxiosError) {
					const status = e.response?.status
					if (status === 400) {
						error("Unable to create account")
					} else {
						error("Something went wrong, please try again later")
					}
				}
			},
		})
	}

	return (
		<>
			<H2 className={title}>Create a password</H2>
			<AuthProvider>
				<Form form={form} name="formPassword" size="large">
					<Form.Item name="password" rules={[{ required: true }]}>
						<Input.Password placeholder="Password" />
					</Form.Item>
					<Form.Item
						name="confirmPassword"
						rules={[
							{
								required: true,
								message: "Please confirm your password",
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue("password") === value) {
										return Promise.resolve()
									}
									return Promise.reject(
										new Error("Passwords do not match. Please enter the same password in both fields"),
									)
								},
							}),
						]}
					>
						<Input.Password placeholder="Confirm Password" />
					</Form.Item>
					<ValidationBlock validate={validatePassword} />
					<ButtonPrimary onClick={onSubmitHandle} submittable={submittable}>
						Continue
					</ButtonPrimary>
				</Form>
			</AuthProvider>
		</>
	)
}
