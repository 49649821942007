import { format, parse } from "date-fns"
import { AxiosError } from "axios"

const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const removeApiVersion = (url: string): string => {
	return url.replace("/api/v1/", "/")
}

const copyToClipboard = (str: string) => {
	navigator.clipboard.writeText(str)
}

const isAxiosError = (error: unknown): error is AxiosError => {
	return (error as AxiosError).isAxiosError !== undefined
}

const formatTime = (time: string) => {
	if (time) {
		const [hours, minutes, seconds] = time.split(":").map(Number)

		const formatedHours = hours > 0 ? `${hours}h` : ""
		const formatedMinutes = minutes > 0 ? `${minutes}m` : ""
		const formatedSeconds = `${seconds}s`

		return `${formatedHours} ${formatedMinutes} ${formatedSeconds}`
	}
}

const formatEstimateTime = (estimatedSeconds: number) => {
	if (estimatedSeconds) {
		const minutes = Math.floor(estimatedSeconds / 60)
		const seconds = Math.floor(estimatedSeconds % 60)
		const formatedEstimate = `${minutes} min ${seconds} sec left`
		return formatedEstimate
	}
}

const formatDate = (date: string, inputFormat: string, outputFormat: string) => {
	if (date === "") return date
	try {
		const cleanedDate = date.trim()
		const parsedDate = parse(cleanedDate, inputFormat, new Date())

		return format(parsedDate, outputFormat)
	} catch (e) {
		console.error("Error formatting date:", e)
		return String(date)
	}
}

const objectTypeTextTooltip = (type: string) => {
	switch (type) {
		case "left_part_of_dual_object":
			return "Left part"

		case "right_part_of_dual_object":
			return "Right part"

		case "whole_dual_object":
			return "Full object"

		case "shelves_object":
			return "Shelves object"
	}
}

export {
	capitalizeFirstLetter,
	removeApiVersion,
	copyToClipboard,
	isAxiosError,
	formatTime,
	objectTypeTextTooltip,
	formatDate,
	formatEstimateTime,
}
