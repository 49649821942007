import { useMemo } from "react"
import { MenuProps } from "antd"
import { DeleteFileIcon } from "components/ui/icons/fileManagment/DeleteFileIcon"
import { ReprocessIcon } from "components/ui/icons/fileManagment/ReprocessIcon"
import { useDeleteConfirmModalStore } from "store/modal.store"
import { TableAllFilesHandlers } from "./TableAllFilesHandlers"
import { Duplicate } from "components/ui/icons/fileManagment/DuplicateIcom"
import { useCurrentUserStore } from "store/user.store"
import type { TTable } from "types/table.type"
import style from "./TableAllFiles.module.scss"

const { dropDownLabel } = style

// Function to generate dropdown items based on item status
export const TableAllFilesUtils = (records?: TTable[], refetchDrawings?: () => void) => {
	const { currentUser } = useCurrentUserStore()
	const { trigerDeleteConfirmModal, setDeleteFile } = useDeleteConfirmModalStore()
	const { startProcessingHandle, duplicateDrawingHandle } = TableAllFilesHandlers(refetchDrawings)

	const getDropDownItems = (id: number, status: string, fileName: string, owner: string): MenuProps["items"] => {
		const items: MenuProps["items"] = []

		// Insert item at index 0 if status is "processed" and owner is "me"
		if (status === "processed" && owner === "me") {
			items.splice(0, 0, {
				key: "1",
				label: (
					<div className={dropDownLabel}>
						<ReprocessIcon />
						<span>Reprocess</span>
					</div>
				),
				onClick: (e) => {
					e.domEvent.stopPropagation()
					startProcessingHandle(id)
				},
			})
		}

		// Insert item at index 1 if the current user is Admin or Super Admin and owner is "other"
		if ((currentUser?.role === "Admin" || currentUser?.role === "Super Admin") && owner === "other") {
			items.splice(1, 0, {
				key: "2",
				label: (
					<div className={dropDownLabel}>
						<Duplicate />
						<span>Duplicate</span>
					</div>
				),
				onClick: () => duplicateDrawingHandle(id),
			})
		}

		// Insert divider and delete item at index 2 if owner is "me"
		if (owner === "me") {
			items.splice(
				2,
				0,
				{
					type: "divider",
				},
				{
					key: "3",
					danger: true,
					label: (
						<div className={dropDownLabel}>
							<DeleteFileIcon />
							<span>Delete</span>
						</div>
					),
					onClick: () => {
						trigerDeleteConfirmModal()
						setDeleteFile({ id: id, fileName: fileName })
					},
				},
			)
		}

		return items
	}

	// Initial table data
	const initialTableData = useMemo(() => {
		if (records) {
			return records.map((item) => ({
				id: item.id,
				key: item.id,
				author: {
					firstName: item.ownerFirstName,
					lastName: item.ownerLastName,
					owner: item.owner,
				},
				owner: item.owner,
				name: item.name,
				status: item.status,
				processing: {
					status: item.status,
					id: item.id,
					owner: item.owner,
				},
				lastProcessingDate: {
					processedDate: item.lastProcessingDate,
					duration: item.processing_duration,
				},
				uploadDate: item.uploadDate,
				size: item.size,
				ownerId: item.ownerId,
				comments: item.comments,
				action: {
					status: item.status,
					id: item.id,
					owner: item.owner,
					fileName: item.name,
				},
			}))
		}
		return []
	}, [records])

	return { getDropDownItems, initialTableData }
}
