import { useEffect } from "react"
import { Table } from "components/ui/tables/Table/Table"
import { usePaginationStore, useTableStore } from "store/table.store"
import { FileInfo } from "../FileInfo/FileInfo"
import { useNatsProcessing } from "hooks/nats.hook"
import { useMessagesDataStore } from "store/nats.store"
import { DeleteConfirmationModal } from "./layout/DeleteConfirmationModal/DeleteConfirmationModal"
import { useDeleteConfirmModalStore } from "store/modal.store"
import { TableAllFilesHandlers } from "./TableAllFilesHandlers"
import { TableAllFilesColumns } from "./TableAllFilesColumns"
import { TableAllFilesUtils } from "./TableAllFilesUtils"
import type { TTable } from "types/table.type"
import style from "./TableAllFiles.module.scss"

const natsUrl = import.meta.env.VITE_NATS_URL

const { tableAllFIles } = style

type Props = {
	data: {
		records: TTable[]
		totalRecords: number
	}
	refetchDrawings: () => void
	isDrawingsRefetching: boolean
}

export const TableAllFiles = ({ data, refetchDrawings, isDrawingsRefetching }: Props) => {
	const records = data.records

	const { onRowHandle, onChangeTableHandle, deleteFileHandle } = TableAllFilesHandlers(refetchDrawings)
	const columns = TableAllFilesColumns(refetchDrawings)
	const { initialTableData } = TableAllFilesUtils(records, refetchDrawings)

	const { messagesData } = useMessagesDataStore()
	const { tableParams } = usePaginationStore()
	const { updatedTableData, setUpdatedTableData } = useTableStore()
	const { deleteFile } = useDeleteConfirmModalStore()

	// Connecting to NATS
	useNatsProcessing(natsUrl, `user.*.drawings.*.progress`, `user.*.drawings.*.status`)

	// Update table data with new Status and Progress from Nats messages
	useEffect(() => {
		const updatedTableData = initialTableData.map((file) => {
			const fileId = file.id
			const fileName = file.name
			const owner = file.action.owner
			const processFilesIds = Object.keys(messagesData).map(Number)

			if (!processFilesIds.includes(fileId)) {
				return file // Return the original file if drawingId is not in drawingIds
			}

			const status = messagesData[fileId].status ? messagesData[fileId].status : file.status
			const progress = messagesData[fileId].progress

			// Return the updated file object
			return {
				...file,
				processing: {
					status: status,
					progress: progress,
					id: fileId,
					owner: owner,
				},
				status: status,
				action: {
					status: status,
					owner: owner,
					id: fileId,
					fileName: fileName,
				},
			}
		})

		// Update the state with the new table data
		setUpdatedTableData(updatedTableData as TTable[])
	}, [initialTableData, setUpdatedTableData, messagesData])

	return (
		<>
			<Table
				className={`${tableAllFIles} TableAllFiles`}
				onChange={onChangeTableHandle}
				data={updatedTableData}
				onRow={onRowHandle}
				columns={columns}
				current={tableParams.pagination.current}
				total={data?.totalRecords}
				loading={isDrawingsRefetching}
			/>
			<FileInfo />
			<DeleteConfirmationModal deleteFileHandle={deleteFileHandle} deleteFile={deleteFile} />
		</>
	)
}
