import { FirstScreen } from "components/ui/FirstScreen/FirstScreen"
import { useActivateUser } from "hooks/auth.hook"
import { useEffect, useState } from "react"
import { SuccessfullCheck } from "./layout/SuccessfullCheck/SuccessfullCheck"
import { ValidationCheck } from "../../ui/ValidationCheck/ValidationCheck"
import { useSearchParams } from "react-router-dom"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import style from "./PageRegistrationActivation.module.scss"

const { skeleton } = style

export const PageRegistrationActivation = () => {
	const [isSuccessful, setIsSuccessful] = useState<boolean>(true)

	const [searchParams] = useSearchParams()
	const id = searchParams.get("uid")
	const token = searchParams.get("token")

	const { mutate, isPending } = useActivateUser()

	useEffect(() => {
		if (id && token) {
			mutate(
				{ id, token },
				{
					onSuccess: () => {
						setIsSuccessful(true)
					},
					onError: () => {
						setIsSuccessful(false)
					},
				},
			)
		}
	}, [id, token, mutate])

	if (!id || !token) {
		return (
			<FirstScreen>
				<ValidationCheck message="Something went wrong with your activation link." email="suppor@steavens.com" />
			</FirstScreen>
		)
	}

	if (isPending) {
		return (
			<FirstScreen>
				<div className={skeleton} data-testid="spinner">
					<Skeleton rows={8} />
				</div>
			</FirstScreen>
		)
	}

	return (
		<FirstScreen>
			{isSuccessful ? (
				<SuccessfullCheck />
			) : (
				<ValidationCheck
					message="Unfortunately, your account could not be activated."
					email="mailto: someemail@some.com"
				/>
			)}
		</FirstScreen>
	)
}
