import { useEffect, useState } from "react"
import { CloseIcon } from "components/ui/icons/uploadbar/CloseIcon"
import { QuestionArrow } from "components/ui/icons/iaAgent/QuestionArrow"
import { useAskQuestion, useGetQuestions } from "hooks/AIAgent.hook"
import { useNatsAnswerStream } from "hooks/nats.hook"
import { marked } from "marked"
import { AIAgentIcon } from "components/ui/icons/iaAgent/AIAgentIcon"
import style from "./AIAgent.module.scss"

const { aiAgent, header, title, chat, buble, answer, question, listItem, arrow, list, logo } = style

const prompt = import.meta.env.VITE_AI_PROMPT
const natsUrl = import.meta.env.VITE_NATS_URL

type QuestionsType = { id: number; question: string }
type Props = { setIsChatOpen: (isChatOpen: boolean) => void }

export const AIAgent = ({ setIsChatOpen }: Props) => {
	const [topic, setTopic] = useState("")
	// console.log("topic :>", topic)
	const [fullAnswer, setFullAnswer] = useState("")
	const [activeQuestion, setActiveQuestion] = useState("")

	const streamingAnswer = useNatsAnswerStream(natsUrl, topic)
	// console.log("stremingAnswer :>", streamingAnswer)

	const { data: questionsData } = useGetQuestions()
	const predefinedQuestions = questionsData?.questions as QuestionsType[]
	// console.log("questionsData :>", questionsData)

	const { mutate: askQuestionMutation } = useAskQuestion()

	useEffect(() => {
		if (streamingAnswer) {
			setFullAnswer((prev) => prev + streamingAnswer.response)
		}
	}, [streamingAnswer])

	const askQuestionHandle = (id: number, question: string) => {
		setActiveQuestion(question)

		askQuestionMutation(
			{ id, text: prompt },
			{
				onSuccess: (response) => {
					// console.log("response :>", response)
					setTopic(response.data.subject)
				},
				onError: () => {},
			},
		)
	}

	return (
		<div className={aiAgent}>
			<div className={header}>
				<div className={logo}>
					<AIAgentIcon />
					<span className={title}>AI Agent</span>
				</div>
				<button onClick={() => setIsChatOpen(false)}>
					<CloseIcon />
				</button>
			</div>
			<div className={chat}>
				<div className={`${buble} ${answer}`}>
					<span>Hey!</span>
				</div>
				<div className={`${buble} ${answer}`}>
					<span>Choose from the listed options or type your question below:</span>
					<ul className={list}>
						{predefinedQuestions
							? predefinedQuestions.map((item) => (
									<li className={listItem} key={item.id} onClick={() => askQuestionHandle(item.id, item.question)}>
										<div className={arrow}>
											<QuestionArrow />
										</div>
										<span>{item.question}</span>
									</li>
								))
							: null}
					</ul>
				</div>
				{activeQuestion ? (
					<div className={`${buble} ${question}`}>
						<span>{activeQuestion}</span>
					</div>
				) : null}
				{streamingAnswer ? (
					<div className={`${buble} ${answer}`}>
						<span dangerouslySetInnerHTML={{ __html: marked(fullAnswer) }} />
					</div>
				) : null}
			</div>
		</div>
	)
}
