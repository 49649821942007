import { useRef, useState } from "react"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { Modal } from "components/ui/modal/Modal"
import { H2 } from "components/ui/typography/titles/Titles"
import { Segmented } from "components/ui/segmented/Segmented"
import { SimpleObject } from "./layout/SimpleObject/SimpleObject"
import { DualPartObject } from "./layout/DualPartObject/DualPartObject"
import { useCreateDualCategory, useCreateSimpleCategory, useObjectsCategories } from "hooks/objectsCategories.hook"
import { ButtonSecondary } from "components/ui/buttons/ButtonSecondary/ButtonSecondary"
import { useAddDualPartCategoryStore, useAddSimpleCategoryStore } from "store/modal.store"
import { useCurrentUserStore } from "store/user.store"
import { useMessage } from "hooks/message.hook"
import { AxiosError } from "axios"
import type { IAddObjectMethods } from "types/configuration.type"
import style from "./AddModelObject.module.scss"

const { addModelObjectButton, container, addModelObject, title, segmentedContent, bar, button } = style

// Segmented optipons
const segmetedOptions = [
	{ label: "Simple object", value: "simple" },
	{ label: "Dual part object", value: "dual" },
]

type Props = { objectCategoriesRefetch: () => void }

export const AddModelObject = ({ objectCategoriesRefetch }: Props) => {
	const { category: simpleCategory } = useAddSimpleCategoryStore()
	const { category: dualCategory } = useAddDualPartCategoryStore()
	const { currentUser } = useCurrentUserStore()

	const { data: objectCategoriesData } = useObjectsCategories()
	const { mutate: simpleCategoryMutation } = useCreateSimpleCategory()
	const { mutate: dualCategoryMutation } = useCreateDualCategory()
	const { success: successMessage, error: errorMessage } = useMessage()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [segmentedValue, setSegmentedValue] = useState<string | number>("simple")

	const simpleObjectRef = useRef<IAddObjectMethods>(null)
	const dualPartObjectRef = useRef<IAddObjectMethods>(null)

	const errorHandling = (e: AxiosError) => {
		const data = e.response?.data
		const status = e.response?.status
		if (status === 400) {
			if (data) {
				Object.entries(data).map(([key, value]) => {
					const transformedKey = key
						.split("_")
						.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
						.join(" ")

					const transformedValue = value[0]
						.split(" ")
						.map((word: string, index: number) => (index === 0 ? word.charAt(0).toLowerCase() + word.slice(1) : word))
						.join(" ")

					errorMessage(`${transformedKey}: ${transformedValue}`)
				})
			}
		}
	}

	// Handle save button click.
	// Call mutation depending on the selected option in the segmented control.
	const saveHandle = () => {
		if (segmentedValue === "simple") {
			simpleCategoryMutation(
				{
					label: simpleCategory.label,
					name: simpleCategory.name,
					topLevelCategory: simpleCategory.topLevelCategory,
					superCategory: simpleCategory.superCategory,
					modelNumbers: simpleCategory.modelNumbers,
				},
				{
					onSuccess: () => {
						successMessage("Category created")
						setIsModalOpen(false)
						objectCategoriesRefetch()
						simpleObjectRef.current?.resetForm()
					},
					onError: (e) => {
						if (e instanceof AxiosError) {
							errorHandling(e)
						}
					},
				},
			)
		} else if (segmentedValue === "dual") {
			dualCategoryMutation(
				{
					label: dualCategory.label,
					name: dualCategory.name,
					topLevelCategory: dualCategory.topLevelCategory,
					superCategory: dualCategory.superCategory,
					leftModelNumbers: dualCategory.leftModelNumbers,
					rightModelNumbers: dualCategory.rightModelNumbers,
					wholeModelNumbers: dualCategory.wholeModelNumbers,
				},
				{
					onSuccess: () => {
						successMessage("Category created")
						setIsModalOpen(false)
						objectCategoriesRefetch()
						dualPartObjectRef.current?.resetForm()
					},
					onError: (e) => {
						if (e instanceof AxiosError) {
							errorHandling(e)
						}
					},
				},
			)
		}
	}

	const addModelObjectHandle = () => {
		setIsModalOpen(true)
	}

	const onChangeSegmentedHandle = (value: string | number) => {
		setSegmentedValue(value)
	}

	return (
		<>
			{currentUser?.role === "Super Admin" && (
				<ButtonPrimary onClick={addModelObjectHandle} className={addModelObjectButton}>
					Add model object
				</ButtonPrimary>
			)}
			<Modal
				width="100%"
				isModalOpen={isModalOpen}
				setModalClose={() => setIsModalOpen(false)}
				className="addMObjectModal"
			>
				<div className={addModelObject}>
					<div className={container}>
						<H2 className={title}>Add new object</H2>
						<Segmented options={segmetedOptions} onChange={onChangeSegmentedHandle} />
						<div className={segmentedContent}>
							{segmentedValue === "simple" && (
								<SimpleObject objectCategoriesData={objectCategoriesData} ref={simpleObjectRef} />
							)}
							{segmentedValue === "dual" && (
								<DualPartObject objectCategoriesData={objectCategoriesData} ref={dualPartObjectRef} />
							)}
						</div>
					</div>
					<div className={bar}>
						<ButtonSecondary className={button} onClick={() => setIsModalOpen(false)}>
							Cancel
						</ButtonSecondary>
						<ButtonPrimary className={button} onClick={saveHandle}>
							Save
						</ButtonPrimary>
					</div>
				</div>
			</Modal>
		</>
	)
}
