import { axiosInstance } from "api/axios.instance"

class ObjectsCategoriesClass {
	static BASE_URL = "/categories"

	async getObjectsCategories() {
		return axiosInstance.get(`${ObjectsCategoriesClass.BASE_URL}/`)
	}

	async includeInProcessing(isIncludedInProcessing: boolean, categoryId: number) {
		return axiosInstance.patch(`${ObjectsCategoriesClass.BASE_URL}/${categoryId}/usedInProcessingStatus`, {
			status: isIncludedInProcessing,
		})
	}

	async updateModelNumbers(modelNumbers: string[], categoryId: number | null) {
		return axiosInstance.patch(`${ObjectsCategoriesClass.BASE_URL}/${categoryId}/modelNumbers`, {
			model_numbers: modelNumbers,
		})
	}

	async createSimpleCategory(
		name: string,
		label: string,
		topLvelCategory: string,
		superCategory: string,
		modelNumbers: string[],
	) {
		return axiosInstance.post(`${ObjectsCategoriesClass.BASE_URL}/simple/`, {
			name,
			label,
			top_level_category_name: topLvelCategory,
			super_category_name: superCategory,
			model_numbers: modelNumbers,
		})
	}

	async createDualCategory(
		name: string,
		label: string,
		topLvelCategory: string,
		superCategory: string,
		leftModelNumbers: string[],
		rightModelNumbers: string[],
		wholeModelNumbers: string[],
	) {
		return axiosInstance.post(`${ObjectsCategoriesClass.BASE_URL}/dual_object/`, {
			base_name: name,
			base_label: label,
			top_level_category_name: topLvelCategory,
			super_category_name: superCategory,
			left_cat_model_numbers: leftModelNumbers,
			right_cat_model_numbers: rightModelNumbers,
			whole_cat_model_numbers: wholeModelNumbers,
		})
	}
}

export default new ObjectsCategoriesClass()
