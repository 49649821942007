import { useEffect, useState } from "react"
import { DocumentCard } from "./layout/DocummentCard/DocumentCard"
import { useFileDownload, usePresignedDownloadUrl } from "hooks/downloadFIle.hook"
import { useMessage } from "hooks/message.hook"
import { useFileModalStore } from "store/modal.store"
import { removeApiVersion } from "helpers/utils.helper"
import { Details } from "./layout/Details/Details"
import { useDocuments } from "hooks/drawings.hook"
import { useSearchParams } from "react-router-dom"
import { Skeleton } from "components/ui/skeleton/Skeleton"
import { GeneralFeedback } from "./layout/GeneralFeedback/GeneralFeedback"
import { DocumentModal } from "./layout/DocumentModal/DocumentModal"
import type { TFileInfo } from "types/drawer.type"
import type { TStopPropagation } from "types/default.type"
import style from "./Documents.module.scss"
import { useCurrentUserStore } from "store/user.store"

const { documentsStyles, files, skeleton, generalFeedback } = style

type Props = {
	drawingStatus: string
	drawingOwner: string
}

export const Documents = ({ drawingStatus, drawingOwner }: Props) => {
	const [previewLink, setPreviewLink] = useState<string>()
	const [downloadData, setDownloadData] = useState({
		fileName: "",
		downloadLink: "",
		type: "",
	})
	const [previewData, setPreviewData] = useState<string | null>(null)
	const [reloadTrigger, setReloadTrigger] = useState(false)

	const { downloadFile } = useFileDownload()
	const { error: errorMessage } = useMessage()
	const { refetch: refetchDownload } = usePresignedDownloadUrl(downloadData.downloadLink)
	const { refetch: refetchPreview } = usePresignedDownloadUrl(previewData)

	const { isFileModalOpen, triggerFileModal, setFileModalClose } = useFileModalStore()
	const { currentUser } = useCurrentUserStore()

	const [searchParams] = useSearchParams()
	const drawingId = Number(searchParams.get("drawingID"))
	const processingId = Number(searchParams.get("processingID"))

	const { data: documents, isPending: isDocumentsPending } = useDocuments(drawingId, processingId, drawingStatus)
	const allDocuments = documents?.documents

	// Show the General Feedback component if the current user is Super Admin or user own files and the drawing is processed or failed
	const isOwner = drawingOwner === "me"
	const isSuperAdmin = currentUser?.role === "Super Admin"
	const isProcessedOrFailed = drawingStatus === "processed" || drawingStatus === "failed"
	const shouldShowFeedback = (isOwner && isProcessedOrFailed) || (isSuperAdmin && isProcessedOrFailed)

	// Toggle the reloadTrigger state to force re-fetching the presigned URL and reloading the document
	const forceReload = () => {
		setReloadTrigger(!reloadTrigger)
	}

	// Handles setting document data for download
	const downloadFileHandle = (fileName: string, downloadLink: string, type: string, e: TStopPropagation) => {
		e.stopPropagation()
		const cleanLink = removeApiVersion(downloadLink)
		setDownloadData({ downloadLink: cleanLink, fileName, type })
	}

	// Handles opening file preview
	const openFilePreviewHandle = (downloadLink: string) => {
		const cleanLink = removeApiVersion(downloadLink)
		setPreviewData(cleanLink)
		forceReload()
	}

	// Effect for fetching presigned URL and setting it for preview
	useEffect(() => {
		if (previewData) {
			refetchPreview()
				.then((result) => {
					if (result.isSuccess && result.data) {
						const downloadUrl = result.data.download_url
						setPreviewLink(downloadUrl)
						triggerFileModal()
					}
				})
				.catch(() => {
					errorMessage("Something went wrong, please try again later")
				})
		}
	}, [previewData, refetchPreview, triggerFileModal, errorMessage, reloadTrigger])

	// Effect for downloading files when downloadData changes
	useEffect(() => {
		if (downloadData.downloadLink) {
			refetchDownload()
				.then((result) => {
					if (result.isSuccess && result.data) {
						const downloadUrl = result.data.download_url
						// Use the presigned URL for downloading the file
						downloadFile(downloadUrl, downloadData.fileName, downloadData.type).catch((e) => {
							if (e) errorMessage("Something went wrong, please try again later")
						})
						setDownloadData({ downloadLink: "", fileName: "", type: "" })
					}
				})
				.catch(() => {
					errorMessage("Something went wrong, please try again later")
				})
		}
	}, [downloadData, refetchDownload, downloadFile, errorMessage])

	return (
		<>
			<div className={documentsStyles}>
				<Details />
				{isDocumentsPending ? (
					<div className={skeleton}>
						<Skeleton rows={4} />
					</div>
				) : (
					<div className={files}>
						{allDocuments
							? allDocuments.map((item: TFileInfo) => (
									<DocumentCard
										key={item.id}
										file={item}
										downloadFile={(e) => downloadFileHandle(item.name, item.download_endpoint, item.type, e)}
										openFilePreview={() => openFilePreviewHandle(item.download_endpoint)}
									/>
								))
							: null}
					</div>
				)}
				<div className={generalFeedback}>
					{/* Show the General Feedback component if the current user is Super Admin or user own files and the drawing is processed or failed */}
					{shouldShowFeedback ? <GeneralFeedback /> : null}
				</div>
			</div>
			<DocumentModal
				isFileModalOpen={isFileModalOpen}
				setFileModalClose={setFileModalClose}
				previewLink={previewLink}
			/>
		</>
	)
}
