import { useMutation, useQuery } from "@tanstack/react-query"
import objectsCategoriesService from "services/objectsCategories.service"
import type { TSimpleCategory, TDualCategory } from "types/modal.type"

export const useObjectsCategories = () => {
	return useQuery({
		queryKey: ["objectsCategories"],
		queryFn: () => objectsCategoriesService.getObjectsCategories(),
		select: ({ data }) => data,
	})
}

export const useIncludeInProcessing = () => {
	return useMutation({
		mutationKey: ["includeInProcessing"],
		mutationFn: ({ isIncludedInProcessing, categoryId }: { categoryId: number; isIncludedInProcessing: boolean }) =>
			objectsCategoriesService.includeInProcessing(isIncludedInProcessing, categoryId),
	})
}

export const useUpdateModelNumbers = () => {
	return useMutation({
		mutationKey: ["updateModelNumbers"],
		mutationFn: ({ modelNumbers, categoryId }: { modelNumbers: string[]; categoryId: number | null }) =>
			objectsCategoriesService.updateModelNumbers(modelNumbers, categoryId),
	})
}

export const useCreateSimpleCategory = () => {
	return useMutation({
		mutationKey: ["createSimpleCategory"],
		mutationFn: ({ name, label, topLevelCategory, superCategory, modelNumbers }: TSimpleCategory) =>
			objectsCategoriesService.createSimpleCategory(name, label, topLevelCategory, superCategory, modelNumbers),
	})
}

export const useCreateDualCategory = () => {
	return useMutation({
		mutationKey: ["createDualCategory"],
		mutationFn: ({
			name,
			label,
			topLevelCategory,
			superCategory,
			leftModelNumbers,
			rightModelNumbers,
			wholeModelNumbers,
		}: TDualCategory) =>
			objectsCategoriesService.createDualCategory(
				name,
				label,
				topLevelCategory,
				superCategory,
				leftModelNumbers,
				rightModelNumbers,
				wholeModelNumbers,
			),
	})
}
