export const AIAgentIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.4"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.716 12.5236C18.716 12.0786 18.466 11.6876 18.068 11.5046L13.898 9.52658L11.981 5.41758C11.795 5.01658 11.405 4.76758 10.963 4.76758C10.521 4.76758 10.131 5.01658 9.94398 5.41758L7.96698 9.58558L3.86098 11.5026C3.45898 11.6876 3.20898 12.0786 3.20898 12.5236C3.20898 12.9676 3.45898 13.3586 3.85798 13.5416L8.02598 15.5186L9.94398 19.6296C10.131 20.0296 10.521 20.2776 10.963 20.2776C11.404 20.2776 11.795 20.0296 11.981 19.6286L13.959 15.4616L18.064 13.5436C18.466 13.3586 18.716 12.9676 18.716 12.5236Z"
				fill="#0047FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.0931 4.68198C20.0931 4.55948 20.0243 4.45185 19.9147 4.40147L18.7668 3.85696L18.239 2.72581C18.1878 2.61542 18.0805 2.54688 17.9588 2.54688C17.8371 2.54688 17.7298 2.61542 17.6783 2.72581L17.134 3.87319L16.0037 4.40091C15.8931 4.45184 15.8242 4.55948 15.8242 4.68198C15.8242 4.80421 15.8931 4.91185 16.0029 4.96222L17.1503 5.50646L17.6783 6.63815C17.7298 6.74826 17.8371 6.81653 17.9588 6.81653C18.0802 6.81653 18.1878 6.74826 18.239 6.63787L18.7836 5.49076L19.9136 4.96276C20.0243 4.91184 20.0931 4.80421 20.0931 4.68198Z"
				fill="#0047FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.4599 18.8788C22.4599 18.7313 22.3771 18.6017 22.2452 18.5411L20.8634 17.8856L20.2281 16.524C20.1665 16.3911 20.0372 16.3086 19.8908 16.3086C19.7443 16.3086 19.6151 16.3911 19.5531 16.524L18.898 17.9052L17.5373 18.5404C17.4041 18.6017 17.3213 18.7313 17.3213 18.8788C17.3213 19.0259 17.4041 19.1555 17.5363 19.2161L18.9175 19.8712L19.5531 21.2335C19.6151 21.3661 19.7443 21.4483 19.8908 21.4483C20.0369 21.4483 20.1665 21.3661 20.2281 21.2332L20.8836 19.8523L22.2439 19.2168C22.3771 19.1555 22.4599 19.0259 22.4599 18.8788Z"
				fill="#0047FF"
			/>
		</svg>
	)
}
