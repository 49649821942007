import { useEffect, useState, useImperativeHandle, forwardRef } from "react"
import { Form, Input } from "antd"
import { H4 } from "components/ui/typography/titles/Titles"
import { Select } from "components/ui/select/Select"
import { ModelNumbersTextarea } from "components/ui/ModelNumbersTextarea/ModelNumbersTextarea"
import { useCategories } from "hooks/categories.hook"
import { useAddSimpleCategoryStore } from "store/modal.store"
import { AddModelObjectProvider } from "components/providers/Forms.provider"
import type { TTopLevelCategory } from "types/drawer.type"
import type { IAddObjectMethods } from "types/configuration.type"
import style from "./SimpleObject.module.scss"

const { addModelObjectForm, block, label, description, endOfSection, startOfSection, title, select } = style

type Props = {
	objectCategoriesData: TTopLevelCategory[]
}

export const SimpleObject = forwardRef<IAddObjectMethods, Props>(({ objectCategoriesData }: Props, ref) => {
	const [form] = Form.useForm()
	const values = Form.useWatch([], form)

	const [selectedTopVevelCategory, setSelectedTopLevelCategory] = useState<string | number | undefined>()
	const [modelNumbers, setModelNumbers] = useState<string[]>([])

	const { topLevelCategories, superCategories } = useCategories(objectCategoriesData, selectedTopVevelCategory)
	const { setFormValues } = useAddSimpleCategoryStore()

	useEffect(() => {
		setFormValues({
			name: values?.name,
			label: values?.label,
			topLevelCategory: values?.topLevelCategory,
			superCategory: values?.superCategory,
			modelNumbers,
		})
	}, [modelNumbers, setFormValues, values])

	// Change top level category
	const onChangeTopLevelCategoryHandle = (value: string | number) => {
		setSelectedTopLevelCategory(value)
		form.resetFields(["superCategory"])
	}

	useImperativeHandle(ref, () => ({
		resetForm: () => form.resetFields(),
	}))

	return (
		<AddModelObjectProvider>
			<Form form={form} size="large">
				<div className={addModelObjectForm}>
					<div className={block}>
						<span className={label}>Name</span>
						<Form.Item name="name" rules={[{ required: true, message: "Name is required" }]}>
							<Input />
						</Form.Item>
					</div>
					<div className={`${block} ${endOfSection}`}>
						<span className={label}>Label</span>
						<span className={description}>
							<span className={description}>Used in train set</span>
							<Form.Item
								name="label"
								required={true}
								rules={[
									{ required: true, message: "Label is reuired" },
									{ pattern: /^l:/, message: 'Label must start with ":l"' },
								]}
							>
								<Input />
							</Form.Item>
						</span>
					</div>
					<div className={block}>
						<H4 className={title}>Categories</H4>
						<span className={label}>Top level category</span>
						<Form.Item
							name="topLevelCategory"
							required={true}
							rules={[{ required: true, message: "Top level category is required" }]}
						>
							<Select
								placeholder="Select category"
								allowClear={false}
								variant="outlined"
								options={topLevelCategories || []}
								onChange={onChangeTopLevelCategoryHandle}
								className={select}
							/>
						</Form.Item>
					</div>
					<div className={block}>
						<span className={label}>Super category</span>
						<Form.Item
							name="superCategory"
							required={true}
							rules={[{ required: true, message: "Super category is required" }]}
						>
							<Select
								placeholder="Select category"
								variant="outlined"
								allowClear={false}
								className={select}
								options={superCategories || []}
							/>
						</Form.Item>
					</div>
					<div className={`${block} ${startOfSection}`}>
						<H4 className={title}>Model numbers</H4>
						<ModelNumbersTextarea modelNumbers={modelNumbers} setModelNumbers={setModelNumbers} />
					</div>
				</div>
			</Form>
		</AddModelObjectProvider>
	)
})
