import { useRef, useState } from "react"
import { Modal } from "components/ui/modal/Modal"
import { useOpenFilePreview } from "hooks/openFilePreview.hook"
import { ButtonPrimary } from "components/ui/buttons/ButtonPrimary/ButtonPrimary"
import { AIAgent } from "./layout/AIAgent"
import { AIAgentWhiteIcon } from "components/ui/icons/iaAgent/AIAgentWhiteIcon"
import style from "./DocumentModal.module.scss"

const { container, pdfViewerContainer, aiAgentButton, aiAgentContainer, aiAgentContainerOpen, pdfViewerContainerOpen } =
	style

type Props = {
	isFileModalOpen: boolean
	setFileModalClose: () => void
	previewLink: string | undefined
}

export const DocumentModal = ({ isFileModalOpen, setFileModalClose, previewLink }: Props) => {
	const [isChatOpen, setIsChatOpen] = useState(false)

	// Use the custom hook to open file preview
	const viewer = useRef(null)
	useOpenFilePreview({ fileLink: previewLink, previewRef: viewer })

	return (
		<Modal isModalOpen={isFileModalOpen} setModalClose={setFileModalClose} width="100%">
			<div className={container}>
				<div className={`${pdfViewerContainer} ${isChatOpen ? pdfViewerContainerOpen : ""}`} ref={viewer}>
					<ButtonPrimary onClick={() => setIsChatOpen(true)} className={aiAgentButton}>
						<AIAgentWhiteIcon />
						AI Agent
					</ButtonPrimary>
				</div>
				<div className={`${aiAgentContainer} ${isChatOpen ? aiAgentContainerOpen : ""}`}>
					<AIAgent setIsChatOpen={setIsChatOpen} />
				</div>
			</div>
		</Modal>
	)
}
