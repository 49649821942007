import { useCollapseObjects } from "hooks/collapseObjects.hook"
import { CategoriesColumns } from "./CategoriesColumns"
import { Collapse } from "components/ui/collapse/Collapse"
import { isAxiosError } from "helpers/utils.helper"
import { ModelNumbersModal } from "../ModelNumbersModal/ModelNumbersModal"
import { TTopLevelCategory } from "types/drawer.type"
import style from "./Categories.module.scss"

const { errorMessage } = style

type Props = {
	objectCategoriesRefetch: () => void
	objectCategoriesError: Error | null
	objectCategoriesData: TTopLevelCategory[]
}

export const Categories = ({ objectCategoriesData, objectCategoriesRefetch, objectCategoriesError }: Props) => {
	const tableColumns = CategoriesColumns()

	const { generateCollapseItems } = useCollapseObjects(tableColumns)
	const collapseItems = generateCollapseItems(objectCategoriesData)

	if (objectCategoriesError) {
		if (isAxiosError(objectCategoriesError)) {
			const status = objectCategoriesError.response?.status
			if (status === 404) {
				return <div className={errorMessage}>Objects categories config not found</div>
			} else {
				return <div className={errorMessage}>Something went wrong, please try again later</div>
			}
		} else {
			return <div className={errorMessage}>An unexpected error occurred</div>
		}
	}

	return (
		<>
			<div data-testid="collapse">
				<Collapse items={collapseItems} />
			</div>
			<ModelNumbersModal refetchObjectCategories={objectCategoriesRefetch} />
		</>
	)
}
